.delay-product-box {
    margin: 20px 0 0 0;
    list-style: none;
    font-size: 18px;
    width: 100%;
    overflow: hidden;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__action {
        color: grey;
        line-height: unset;

        &_green {
            a, button, .svg-inline--fa {
                font-size: 18px;
                color: green;
            }
        }

        &_blue {
            a, button, .svg-inline--fa {
                font-size: 18px;
                color: blue;
            }
        }

        &_red {
            a, button, .svg-inline--fa {
                font-size: 18px;
                color: red;
            }
        }

        &_selected {
            border: 1px solid black;
        }
    }
}
