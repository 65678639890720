html,
body {
  height: 100%;
}
.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -60px;
  padding: 0 0 60px;
}
.wrap > .container {
  padding: 70px 15px 20px;
}
.footer {
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}
@media (min-width: 768px) {
  #main_nav_bar .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}
#task_queue_panel {
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  font-size: 14px;
  position: fixed;
  top: 100px;
  right: -550px;
  width: 550px;
  z-index: 500;
}
#task_queue_panel.active {
  right: 0;
}
#task_queue_panel .panel-control {
  position: absolute;
  top: 34px;
  left: -65px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #f5f5f5;
  border-radius: 5px 5px 0 0;
  border: 1px solid #ddd;
  transform: rotate(-90deg);
  box-shadow: -1px -1px 5px #888;
  color: #777;
  cursor: pointer;
}
#task_queue_panel .panel-control:hover {
  color: #333;
}
#task_queue_panel .panel-group {
  max-height: 60vh;
  overflow-y: auto;
}
#task_queue_panel .panel-heading {
  padding: 5px 10px;
}
#task_queue_panel .panel-title {
  font-size: 14px;
}
#task_queue_panel h2 {
  margin: 0 0 15px 0;
}
.not-set {
  color: #c55;
  font-style: italic;
}
/* add sorting icons to gridview sort links */
a.asc:after,
a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}
a.asc:after {
  content: "\e151";
}
a.desc:after {
  content: "\e152";
}
.sort-numerical a.asc:after {
  content: "\e153";
}
.sort-numerical a.desc:after {
  content: "\e154";
}
.sort-ordinal a.asc:after {
  content: "\e155";
}
.sort-ordinal a.desc:after {
  content: "\e156";
}
.grid-view .filters input,
.grid-view .filters select {
  min-width: 40px;
}
.grid-view .filters .form-control {
  height: auto;
  padding: 2px 4px;
}
.grid-view .action-column {
  text-align: right;
  white-space: nowrap;
}
.grid-view .action-column a,
.grid-view .action-column button {
  color: #000000;
}
.grid-view .action-column a.delete,
.grid-view .action-column button.delete {
  color: darkred;
}
a.delete {
  color: darkred;
}
.panel-default > .panel-heading .badge-primary,
.badge-primary {
  background-color: #337ab7;
}
.panel-default > .panel-heading .badge-success,
.badge-success {
  background-color: #5cb85c;
}
.panel-default > .panel-heading .badge-info,
.badge-info {
  background-color: #5bc0de;
}
.panel-default > .panel-heading .badge-warning,
.badge-warning {
  background-color: #f0ad4e;
}
.panel-default > .panel-heading .badge-danger,
.panel-default > .panel-heading .badge-important,
.badge-danger,
.badge-important {
  background-color: #d9534f;
}
.hint-block {
  display: block;
  margin-top: 5px;
  color: #999;
}
.error-summary {
  color: #a94442;
  background: #fdf7f7;
  border-left: 3px solid #eed3d7;
  padding: 10px 20px;
  margin: 0 0 15px 0;
}
/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
  padding: 9px 15px;
  border: none;
}
@media (max-width: 767px) {
  .nav li > form > button.logout {
    display: block;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
  }
}
.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
  text-decoration: none;
}
.nav > li > form > button.logout:focus {
  outline: none;
}
.btn {
  white-space: normal;
}
.list-group.list-group-root {
  padding: 0;
  overflow: hidden;
}
.list-group.list-group-root .list-group {
  margin-bottom: 0;
}
.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}
.list-group.list-group-root > .list-group-item:first-child {
  border-top-width: 0;
}
.list-group.list-group-root > .list-group > .list-group-item {
  padding-left: 30px;
}
.list-group.list-group-root > .list-group > .list-group > .list-group-item {
  padding-left: 45px;
}
.list-group .list-group-item {
  width: 100%;
  overflow: hidden;
}
body {
  padding-top: 36px;
  font-size: 13px;
  line-height: 16px;
}
.sidebar-nav {
  padding: 9px 0;
}
.dropdown-menu form {
  padding: 0;
}
.dropdown-menu button.btn-link {
  display: block;
  width: 100%;
  color: #333333;
  font-weight: normal;
  text-decoration: none;
  padding: 3px 20px;
  text-align: left;
  border: none;
  white-space: nowrap;
  line-height: 1.42857143;
}
.dropdown-menu button.btn-link:hover,
.dropdown-menu button.btn-link:focus {
  color: #262626;
  background-color: #f5f5f5;
}
.required label.control-label:after {
  content: ' *';
  color: red;
}
h1 {
  margin-bottom: 9px;
}
.breadcrumb {
  margin: 0;
}
.main-flash-area .alert {
  margin: 0;
  border-radius: 0;
}
.table a.act {
  color: gray;
}
.table a.act:hover {
  color: black;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 4px 5px;
}
.table td.h {
  color: lightgray;
}
.table td.h a {
  color: lightgray;
}
.table .h td {
  color: lightgray;
}
.table .h td a {
  color: lightgray;
}
.table .h.error td {
  color: darkred;
}
.table .in-blacklist td {
  background-color: #000000;
  color: #ffffff;
}
.table .in-blacklist.error td {
  color: darkred;
}
dl.dl-compact dd {
  margin-bottom: 5px;
  padding-left: 20px;
}
.jeditable,
.jeditable_select {
  cursor: text;
  padding: 3px;
  white-space: nowrap;
}
.jeditable:hover,
.jeditable_select:hover,
.jeditable_text:hover {
  background-color: yellow;
}
.jeditable_select .great {
  background-color: lightgreen;
}
.jeditable_select .warn {
  color: darkred;
}
span.jeditable_text {
  background-color: lightgoldenrodyellow;
}
span.jeditable form {
  display: inline;
}
#sidebar .shop-logo {
  margin-bottom: 10px;
  margin-left: auto;
  width: 50%;
}
.order .table td .shop-logo,
.wait-partner .table td .shop-logo {
  width: 50px;
  float: right;
}
.order-create .shop-logo {
  width: 200px;
  float: right;
}
td select,
td input,
td form {
  margin: 0;
}
h2 {
  font-size: 20px;
  line-height: 25px;
}
.page-header {
  padding: 0;
  margin: 0 0 10px 0;
}
.page-header h1 {
  font-size: 20px;
  line-height: 20px;
  margin: 5px 0;
}
.page-header h1 small {
  font-size: 16px;
}
.page-additional-links {
  margin-right: 10px;
}
.page-additional-links .admin-favorites-delete {
  color: #fcb116;
}
.page-additional-links .admin-favorites-add {
  color: #888;
}
img {
  max-width: none;
}
.thumbnails img,
img.resize,
.carousel img {
  max-width: 100%;
}
.thumbnails h3 {
  font-size: 16px;
  line-height: normal;
}
.extruder {
  position: fixed;
  cursor: default;
  background: url("/js/plugin/jquery.mb.extruder.2.3/elements/extruder_blank.png");
}
.extruder .header {
  height: 27px;
}
.extruder .content {
  display: none;
  background: #ffffff;
  padding: 10px 0;
}
.extruder.open .content {
  box-shadow: 2px 0 5px #999999;
}
.extruder .footer {
  display: none;
  height: 10px;
  background: #000000;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 0 5px #999999;
}
.extruder.top .flap {
  color: #ffffff;
  font: 18px/28px Arial, Helvetica, sans-serif;
  text-align: center;
  display: block;
  margin: auto;
  padding: 0 5px 5px 5px;
  height: 30px;
  width: 180px;
  background: #000000;
  cursor: pointer;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 0 5px #999999;
  text-shadow: 2px 2px 2px #333333;
}
.extruder.top .content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.extruder.bottom .flap {
  position: relative;
  color: white;
  font: 18px/28px Arial, Helvetica, sans-serif;
  text-align: center;
  display: block;
  margin: auto;
  margin-top: 1px;
  padding: 0 5px 5px 5px;
  height: 30px;
  width: 180px;
  background: #000000;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-shadow: 2px 2px 2px #333333;
  box-shadow: 2px 0 5px #999999;
}
.extruder.bottom .footer {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  margin-top: -5px;
}
.extruder.bottom .content {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.extruder.bottom .ext_wrapper {
  bottom: 0;
}
.extruder.left {
  height: 100%;
  background: url("/js/plugin/jquery.mb.extruder.2.3/elements/extruder_blank.png");
}
.extruder.left.open .content {
  box-shadow: 2px 0 5px #999999;
}
.extruder.left .content {
  border-right: 3px solid #000000;
}
.extruder.left .ext_wrapper {
  height: 100%;
}
.extruder.left .footer {
  display: none;
}
.extruder.left .flap {
  font-size: 18px;
  color: white;
  top: 0;
  padding: 10px 0 10px 10px;
  margin-right: -37px;
  background: #000000;
  width: 30px;
  position: absolute;
  right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 8px;
  box-shadow: 2px 0 5px #999999;
}
.extruder.left .flap .flapLabel {
  background: #000000;
}
.extruder.right {
  height: 100%;
  background: url("/js/plugin/jquery.mb.extruder.2.3/elements/extruder_blank.png");
}
.extruder.right .content {
  border-left: 3px solid #000000;
}
.extruder.right.open .content {
  box-shadow: -2px 0 5px #999999;
}
.extruder.right .ext_wrapper {
  height: 100%;
  right: 0;
}
.extruder.right .footer {
  display: none;
}
.extruder.right .flap {
  font-size: 18px;
  color: white;
  top: 0;
  padding: 10px 0 10px 10px;
  background: #000000;
  width: 30px;
  position: absolute;
  left: -37px;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0;
  box-shadow: -2px 0 5px #999999;
}
.extruder.right .flap .flapLabel {
  background: #000000;
}
.extruder .settingsBtn {
  display: block;
  position: absolute;
  width: 36px;
  height: 36px;
  background: url("/js/plugin/jquery.mb.extruder.2.3/elements/settingsBtn.png") no-repeat bottom;
  cursor: pointer;
  right: -3px;
  top: -4px;
}
.extruder .optionsPanel {
  background: url("/js/plugin/jquery.mb.extruder.2.3/elements/fuzz.gif");
  display: none;
  border-bottom: 1px solid #333333;
}
.extruder .optionsPanel .panelVoice a {
  text-decoration: none;
  display: block;
  color: #cccccc;
  padding: 8px;
  padding-left: 20px;
  font-size: 16px;
  text-shadow: 2px 2px 2px #333333;
  border-bottom: 1px solid #000000;
  cursor: pointer;
}
.extruder .optionsPanel .panelVoice a:hover {
  color: #fff;
  background: url("/js/plugin/jquery.mb.extruder.2.3/elements/red_op_50.png");
}
:focus {
  outline: 0;
}
.wait-partner .act_sent,
.partner .act_sent,
.wait-partner .act_notify,
.partner .act_notify,
.wait-partner .act_check,
.partner .act_check,
.wait-partner .act_received,
.partner .act_received {
  white-space: nowrap;
  margin-bottom: 3px;
}
.wait-partner .act_sent.not-checked svg,
.partner .act_sent.not-checked svg,
.wait-partner .act_notify.not-checked svg,
.partner .act_notify.not-checked svg,
.wait-partner .act_check.not-checked svg,
.partner .act_check.not-checked svg,
.wait-partner .act_received.not-checked svg,
.partner .act_received.not-checked svg {
  color: transparent;
}
.wait-partner .act_received,
.partner .act_received {
  color: #000000;
  text-shadow: none;
}
.table tbody tr.received td {
  background-color: orange;
}
.table .progress {
  margin-bottom: 0;
}
.table tr:hover {
  box-shadow: inset 0 0 0 2px mediumvioletred;
}
#statePanel .container {
  height: 100%;
  overflow: auto;
}
#statePanel .panel-body .blur {
  opacity: 0.3;
}
label {
  font-weight: bold;
}
body .ui-widget {
  font-size: 0.9em;
}
.block {
  border: 2px solid #E9E2EE;
  background-color: #f4f0f6;
  margin-bottom: 1em;
  padding: 1em;
}
.add {
  margin-top: 20px;
}
.feedbacks #FeedbackAdminEditForm div.input {
  display: block;
  margin-bottom: 5px;
  margin-top: 10px;
}
.text label {
  display: block;
}
.modal-header h2 {
  margin: 0;
}
.navbar .nav > li > a {
  padding: 7px 15px;
}
.navbar-collapse > form {
  margin: 3px 0;
}
.navbar-collapse > form .form-control {
  height: 29px;
  padding: 4px 12px;
}
.navbar-brand {
  padding: 7px 20px;
  height: auto;
}
.navbar,
.navbar-header {
  min-height: 35px;
  margin: 0;
}
.navbar-toggle {
  padding: 2px 10px;
}
li {
  line-height: 16px;
}
.btn {
  font-size: 13px;
  line-height: 16px;
}
.text-nowrap {
  white-space: nowrap;
}
.text-nowrap .btn {
  white-space: nowrap;
}
.notice-container {
  position: fixed;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  width: 300px;
  z-index: 9000;
}
.notice-container .alert {
  text-align: center;
  position: relative;
}
.notice-container .alert button.close {
  float: none;
  position: absolute;
  top: -2px;
  right: 1px;
  outline: none;
  cursor: pointer;
}
.detail-view th {
  width: 1px;
  white-space: nowrap;
}
.alert > ul {
  margin-bottom: 10px;
}
.editable-area {
  margin-top: 10px;
  padding: 5px;
  border: 1px dashed #cd0a0a;
}
.editable-area__date {
  font-style: italic;
  color: gray;
}
.muted {
  color: #999;
}
input[type="date"].form-control {
  line-height: normal;
}
a .svg-inline--fa {
  color: black;
}
.grid-view .summary {
  float: right;
  line-height: 32px;
}
.grid-view .pagination {
  margin: 0 0 20px 0;
}
.dropdown.text-templates.form-control {
  width: 100%;
  padding: 0;
  border: none;
}
.dropdown.text-templates.form-control .text-template-button {
  margin-top: 5px;
  width: 100%;
}
.label {
  white-space: normal;
  font-size: 11.844px;
  text-align: left;
  display: inline-block;
}
.label-default {
  background-color: #999;
}
.form-inline input,
.form-inline select,
.form-inline .ui-multiselect,
.form-inline .checkbox {
  margin: 0 10px 0 5px;
}
.search-form {
  font-size: 12px;
  padding: 8px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.search-form label,
.search-form input,
.search-form select,
.search-form button {
  font-size: 12px;
}
.search-form input,
.search-form select,
.search-form button {
  padding: 2px 7px;
  height: initial;
}
.search-form button {
  margin-left: 7px;
}
.search-form select {
  line-height: normal;
  height: auto;
}
.search-form hr {
  margin: 5px 0;
}
.search-form.well {
  padding: 10px;
}
.search-active-filter {
  position: relative;
}
.search-active-filter form {
  margin-right: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.search-active-filter form input {
  margin: 0 0 0 5px;
}
.search-active-filter .filter-field {
  display: inline-block;
}
.search-active-filter .form-group {
  white-space: nowrap;
}
.search-active-filter .form-group__chosen {
  white-space: nowrap;
}
.search-active-filter .form-group__chosen .chosen-container {
  width: auto !important;
  min-width: 100px;
  font-size: 12px;
}
.search-active-filter .form-group__chosen .chosen-container .chosen-choices .search-field input {
  height: initial;
  padding: 2px 7px;
}
.search-active-filter .form-group__chosen .chosen-container .chosen-choices .search-choice {
  margin: 1px;
  padding: 2px 20px 2px 2px;
}
.search-active-filter .form-group__chosen .chosen-container .chosen-choices .search-choice .search-choice-close {
  top: 4px;
}
.search-active-filter .add-filter-menu-wrapper {
  display: inline-block;
  margin-right: 5px;
  position: relative;
}
.search-active-filter .search-form-actions {
  position: absolute;
  right: 10px;
  top: 10px;
}
.search-active-filter .save-filter-wrapper {
  display: inline-block;
  margin-right: 5px;
}
.search-active-filter .save-filter-wrapper .save-filter-form {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 10px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.search-active-filter .save-filter-wrapper .save-filter-form.active {
  display: block;
}
.search-active-filter .load-filter-wrapper {
  display: inline-block;
  margin-right: 5px;
}
.search-active-filter .load-filter-wrapper .load-filter-form {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 10px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.search-active-filter .load-filter-wrapper .load-filter-form .delete-filter-btn {
  float: right;
}
.search-active-filter .load-filter-wrapper .load-filter-form.active {
  display: block;
}
.order_tips {
  background: none repeat scroll 0 0 #ffffff;
  border: 1px solid #aaaaaa;
  cursor: pointer;
  display: none;
  list-style: none outside none;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  position: absolute;
  z-index: 20;
  width: auto;
}
.order_tips li {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-bottom-color: #e8e3d7;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.order_tips li:hover {
  background: #e8e3d7;
  border: 1px solid #e8e3d7;
}
#orders-grid .sti:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #4545ff;
  margin-right: 5px;
}
#orders-grid .st_60:before {
  background-color: #009400;
}
#orders-grid .st_65:before {
  background-color: #d90000;
}
.attention,
.attention a {
  color: red;
  font-weight: bold;
}
.np-status {
  color: gray;
  font-size: 10px;
  line-height: 13px;
}
.order-product-delay-list {
  width: 100%;
  font-size: 11px;
  border: none !important;
}
.order-product-delay-list tr {
  border: none !important;
}
.order-product-delay-list tr.received {
  color: green;
}
.order-product-delay-list tr.denied {
  color: red;
}
.order-product-delay-list tr.put-into-cell {
  color: blue;
}
.order-product-delay-list tr.without-delay {
  color: gray;
}
.order-product-delay-list tr td {
  border: none !important;
  padding: 0;
  background-color: transparent !important;
}
.order-product-delay-list tr td.day,
.order-product-delay-list tr td.status,
.order-product-delay-list tr td.title {
  white-space: nowrap;
}
#order-grid .order-product-delay-list {
  margin-bottom: 10px;
}
.order-view .delivery_price {
  width: 200px;
  text-align: right;
}
.order-view .res_sum {
  text-align: right;
  font-size: 2.2em;
  color: gray;
  margin-bottom: 0.5em;
  line-height: normal;
}
.order-view .res_sum strong {
  color: black;
  font-weight: bold;
}
.order-view .blank_sum {
  width: 200px;
  float: right;
}
.order-view .summary {
  display: none;
}
.order-view .status strong {
  color: black;
  font-size: 1.2em;
}
.order-view .distributors-link {
  color: black;
}
.order-view .distributors-link svg {
  margin-right: 2px;
}
.order-view .status .active {
  font-weight: bold;
}
.delay-product-delete,
.delay-product-delete:hover {
  color: darkred;
}
.waybill .delay-product-box {
  margin: 0;
}
.waybill__received td {
  color: green;
}
.waybill__received td a {
  color: green;
  font-weight: bold;
}
.waybill__received.error td {
  color: darkred;
}
.waybill__deleted {
  text-decoration: line-through;
}
.waybill__deleted td {
  color: #d3d3d3;
}
.waybill__deleted td a {
  color: gray;
  font-weight: bold;
}
.waybill__deleted.error td {
  color: darkred;
}
td.waybill__received {
  color: green;
}
td.waybill__received a {
  color: green;
  font-weight: bold;
}
td.waybill__deleted {
  color: #d3d3d3;
  text-decoration: line-through;
}
td.waybill__deleted a {
  color: gray;
  font-weight: bold;
}
.order-product-delay-list {
  width: 100%;
  font-size: 11px;
  border: none !important;
}
.order-product-delay-list tr {
  border: none !important;
}
.order-product-delay-list tr.received {
  color: green;
}
.order-product-delay-list tr.denied {
  color: red;
}
.order-product-delay-list tr th,
.order-product-delay-list tr td {
  padding: 0;
}
.order-product-delay-list tr td {
  border: none !important;
  padding: 0;
  background-color: transparent !important;
}
.order-product-delay-list tr td.day,
.order-product-delay-list tr td.status,
.order-product-delay-list tr td.title {
  white-space: nowrap;
}
.editable {
  border: 1px dashed gray;
  padding: 5px;
  cursor: pointer;
}
.editable:hover {
  background-color: #fafad2;
}
table .editable {
  display: inline-block;
}
table.table tbody tr.away td,
table.table tbody tr:hover.away td {
  background-color: #fed6d7;
}
table.table tbody tr td.away,
table.table tbody tr:hover td.away {
  color: black;
  background-color: #fed6d7;
}
#info .editable,
.blank_sum .editable,
.delivery_price .editable {
  display: block;
}
.label-info_expected-send-date {
  background-color: #985f0d;
}
.waybill-tab a {
  background-color: #efefef;
}
.distributor-order-tab a {
  background-color: rgba(0, 128, 0, 0.1);
}
.table .user-group-1,
.table .user-group-0 {
  color: black !important;
}
.table .user-group-0 {
  background-color: lightsalmon !important;
}
.table .user-group-1 {
  background-color: lightyellow !important;
}
.pack-item {
  border: 2px solid black;
  padding: 0.7em;
  margin-bottom: 1.5em;
}
.pack-item h3 {
  margin-top: 0;
}
.pack-item .date {
  float: right;
  font-style: italic;
}
.pack-item .op {
  color: darkblue;
  margin-top: 0.5em;
  font-size: 1.2em;
}
.pack-item .sa {
  text-align: right;
}
.pack-item.wait-from-storage {
  background-color: #fdf7ed;
  opacity: 0.9;
  border: dashed #f0ad4e;
}
.pack-item.not-validate {
  border-color: #d9534f;
  background-color: #fffbfc;
}
.pack-item.validate {
  border-color: #5cb85c;
  background-color: #eff8ef;
}
.pack-item .table {
  background-color: white;
}
#productsphinxsearch-similartitle {
  width: 50em;
}
.page-actions .dropdown {
  display: inline-block;
}
.delay-product-box {
  margin: 20px 0 0 0;
  list-style: none;
  font-size: 18px;
  width: 100%;
  overflow: hidden;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delay-product-box__action {
  color: grey;
  line-height: unset;
}
.delay-product-box__action_green a,
.delay-product-box__action_green button,
.delay-product-box__action_green .svg-inline--fa {
  font-size: 18px;
  color: green;
}
.delay-product-box__action_blue a,
.delay-product-box__action_blue button,
.delay-product-box__action_blue .svg-inline--fa {
  font-size: 18px;
  color: blue;
}
.delay-product-box__action_red a,
.delay-product-box__action_red button,
.delay-product-box__action_red .svg-inline--fa {
  font-size: 18px;
  color: red;
}
.delay-product-box__action_selected {
  border: 1px solid black;
}
.short-info {
  color: black;
}
.short-info__content {
  max-height: 800px;
  overflow: auto;
}
.short-info__images {
  display: flex;
}
.short-info__images img {
  padding: 0 10px 0 0;
  max-width: 380px;
  height: auto;
}
.short-info__description p {
  margin-bottom: 5px;
}
.short-info__description .table th,
.short-info__description .table td {
  line-height: normal;
}
.short-info__description h1 {
  color: black;
  border-style: none;
  margin: 0 0 7px;
  font-size: 1em;
  line-height: normal;
}
.short-info__description h2 {
  font-size: 1em;
  background-color: #f1f1f1;
  padding: 0 10px;
  clear: both;
}
.short-info__description .price del {
  color: gray;
}
.short-info__menu {
  clear: both;
  width: 100%;
  max-width: 390px;
  overflow: hidden;
  margin-bottom: -4px;
}
.short-info__menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.short-info__menu li,
.short-info__menu li a {
  display: inline-block;
}
.short-info__menu li a {
  padding: 4px;
}
.short-info__menu li a:hover {
  background-color: blue;
  color: white;
}
.qtip {
  max-width: 800px;
}
.post-link {
  display: inline-block;
  margin: 0;
}
.btn-link.post-link__button {
  padding: 0;
}
.grid-view .action-column .post-link__button {
  background-color: transparent;
  border: none;
  padding: 0;
}
.btn-group > .post-link:first-child:not(:last-child):not(.dropdown-toggle) .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .post-link:first-child .btn {
  margin-left: 0;
}
.btn-group > .post-link:last-child:not(:first-child) .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-menu > li > .post-link {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu > li > .post-link:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.break-word {
  word-break: break-word;
}
.sidebar-block {
  border: 1px dashed #d3d3d3;
  padding: 10px;
  margin-bottom: 20px;
}
.sidebar-block_info {
  background-color: #eafbff;
}
.sidebar-block_success {
  background-color: #eff8ef;
}
.sidebar-block_warning {
  background-color: #fffaec;
}
.sidebar-block_danger {
  background-color: #f2dede;
}
.sidebar-block___title {
  font-size: 13px;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 10px;
}
