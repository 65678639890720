.short-info {
    color: black;

    &__content {
        max-height: 800px;
        overflow: auto;
    }

    &__images {
        display: flex;

        img {
            padding: 0 10px 0 0;
            max-width: 380px;
            height: auto;
        }
    }

    &__description {
        p {
            margin-bottom: 5px;
        }

        .table {
            th,
            td {
                line-height: normal;
            }
        }

        h1 {
            color: black;
            border-style: none;
            margin: 0 0 7px;
            font-size: 1em;
            line-height: normal;
        }

        h2 {
            font-size: 1em;
            background-color: #f1f1f1;
            padding: 0 10px;
            clear: both;
        }

        .price del {
            color: gray;
        }
    }

    &__menu {
        clear: both;
        width: 100%;
        max-width: 390px;
        overflow: hidden;
        margin-bottom: -4px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li,
        li a {
            display: inline-block;
        }

        li a {
            padding: 4px;

            &:hover {
                background-color: blue;
                color: white;
            }
        }
    }
}

.qtip {
    max-width: 800px
}
