.sidebar-block {
    border: 1px dashed #d3d3d3;
    padding: 10px;
    margin-bottom: 20px;

    &_info {
        background-color: #eafbff;
    }

    &_success {
        background-color: #eff8ef;
    }

    &_warning {
        background-color: #fffaec;
    }

    &_danger {
        background-color: #f2dede;
    }

    &___title {
        font-size: 13px;
        line-height: normal;
        margin-top: 0;
        margin-bottom: 10px;
    }
}
