.form-inline {
    input,
    select,
    .ui-multiselect,
    .checkbox {
        margin: 0 10px 0 5px;
    }
}

.search-form {
    font-size: 12px;

    padding: 8px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;

    label,
    input,
    select,
    button {
        font-size: 12px;
    }

    input,
    select,
    button {
        padding: 2px 7px;
        height: initial;
    }

    button {
        margin-left: 7px;
    }

    select {
        line-height: normal;
        height: auto;
    }

    hr {
        margin: 5px 0;
    }

    &.well {
        padding: 10px;
    }
}

.search-active-filter {
    position: relative;

    form {
        margin-right: 60px;

        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        input {
            margin: 0 0 0 5px;
        }
    }

    .filter-field {
        display: inline-block;
    }

    .form-group {
        white-space: nowrap;
    }

    .form-group__chosen {
        white-space: nowrap;

        .chosen-container {
            width: auto !important;
            min-width: 100px;
            font-size: 12px;

            .chosen-choices {
                .search-field input {
                    height: initial;
                    padding: 2px 7px;
                }

                .search-choice {
                    margin: 1px;
                    padding: 2px 20px 2px 2px;

                    .search-choice-close {
                        top: 4px;
                    }
                }
            }
        }
    }

    .add-filter-menu-wrapper {
        display: inline-block;
        margin-right: 5px;
        position: relative;
    }

    .search-form-actions {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .save-filter-wrapper {
        display: inline-block;
        margin-right: 5px;

        .save-filter-form {
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 1000;
            display: none;
            float: left;
            min-width: 160px;
            padding: 10px;
            margin: 2px 0 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #ffffff;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            //border: 1px solid #ccc;
            border: 1px solid rgba(0, 0, 0, .15);
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

            &.active {
                display: block;
            }
        }
    }

    .load-filter-wrapper {
        display: inline-block;
        margin-right: 5px;

        .load-filter-form {
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 1000;
            display: none;
            float: left;
            min-width: 160px;
            padding: 10px;
            margin: 2px 0 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #ffffff;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            //border: 1px solid #ccc;
            border: 1px solid rgba(0, 0, 0, .15);
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

            .delete-filter-btn {
                float: right;
            }

            &.active {
                display: block;
            }
        }
    }
}
